<template>
    <div>
        <div class = "row">
            <div v-for="item in images" :key ="item.id" :class = "'col-sm-' + size">
                <img class = "w-100" :src="require('@/assets/' + path + item + '')" alt="">
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    props: ["path","images","size"],
    data() {
        return {

        };
    },
};
</script>