
<template>
    <div>
        <div class="container pb-5">
            <SectionTitle title="Comunicación social"/><br>

            <Images :path="path" :images="images" size = "3"/>

            <TitleText 
            title = "Comunicación social"
            text1 = "Con la finalidad de mantener informados a los pobladores en las áreas de influencia de los centros de operaciones, previo a iniciar las actividades de detección y control de la mosca del Mediterráneo, el equipo de divulgación de los Centros de Operaciones del Programa MOSCAMED realiza diversas acciones orientadas a informar y resolver dudas sobre las actividades técnicas a implementar a autoridades, instituciones, líderes comunitarios, agricultores, administradores y propietarios de fincas y público en general, a través de visitas, reuniones y talleres informativos, alianzas estratégicas institucionales, organización de grupos de trabajo, campañas radiales, atención a la prensa y material promocional, resalta la plataforma digital con la página web y redes sociales."
            numP = "1"
            />

            <TitleText 
            title = "Proyección social"
            text1 = "Así también, dentro de la proyección social comunitaria destacan la asistencia técnica y capacitaciones agropecuarias, parcelas demostrativas y huertos familiares, impulso a proyectos productivos en especial en agricultura orgánica con elaboración de abonos, capacitaciones en temas de interés orientados a mejorar la producción en especial de la fruticultura, cursos de agroindustria artesanal que incluye elaboración de jaleas y mermeladas, recetas de comida y manualidades entre otros. Así como la participación en reuniones y eventos comunitarios."
            numP = "1"
            />

            <Images :path="path" :images="images2" size = "3"/>
        </div>
    </div>
</template>

<script>
import Images from "@/components/Images.vue";
import TitleText from "@/components/TitleText.vue";

export default {
    name: "Home",
    components: {
        Images,
        TitleText,

    },

    data() {
        return {
            images: ["image-1.jpg", "image-2.jpg","image-3.jpg","image-4.jpg","image-5.jpg","image-6.jpg","image-7.jpg","image-8.jpg","image-9.jpg","image-10.jpg","image-11.jpg","image-12.jpg"],
            images2: ["image-13.jpg", "image-14.jpg","image-15.jpg","image-16.jpg","image-17.jpg","image-18.jpg","image-19.jpg","image-20.jpg","image-21.jpg","image-22.jpg","image-23.jpg","image-24.jpg"],
            path: "comunicacion/proyeccionSocial/"
        };
    },
};
</script>
